footer {
  color: #999;
  text-align: center;
  width: 100%;

  @media @phones {
    font-size: 0.8em;
    padding: 1em;
  }

  @media @tablets-portrait, @tablets-landscape {
    padding: 1.5em;
  }

  @media @desktop {
    padding: 2em;
  }
}
