section.article-list {
  float: none;
  margin: auto;
  max-width: @project-max-width;
  overflow: hidden;

  @media @phones {
    padding: 0.5em 1em;
  }

  @media @tablets-portrait, @tablets-landscape {
    padding: 0.5em 1.5em;
  }

  @media @desktop {
    padding: 0.5em 2em;
  }

  header {
    color: #666;
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-align: center;
    width: 100%;

    @media @phones, @tablets-portrait, @tablets-landscape {
      margin: 1em 0;
    }

    @media @desktop {
      margin: 1.5em 0;
    }
  }

  ul {
    list-style-type: none;

    li {
      background-color: #FFFFFF;
      border-radius: 0.2em;
      box-shadow: 2px 2px 10px 0px #B1B1B1;
      margin-top: 1em;
      width: 100%;

      @media @phones {
        padding: 1em;
      }

      @media @tablets-portrait, @tablets-landscape {
        padding: 1.5em;
      }

      @media @desktop {
        padding: 2em;
      }

      a {
        color: inherit;
        text-decoration: none;
        width: 100%;

        h1 {
          margin: 0 0 0.5em 0;
          text-align: left;

          @media @phones {
            font-size: 1em;
          }

          @media @tablets-portrait, @tablets-landscape {
            font-size: 1.2em;
          }

          @media @desktop {
            font-size: 1.4em;
          }
        }
      }

      time {
        color: #888888;

        @media @phones {
          font-size: 0.8em;
        }

        @media @tablets-portrait, @tablets-landscape {
          font-size: 1em;
        }

        @media @desktop {
          font-size: 1.2em;
        }
      }

      &:hover {
        box-shadow: 2px 2px 20px 0px #B1B1B1;
      }
    }
  }
}
