* {
  box-sizing: border-box;
  color: inherit;
  float: left;
  margin: 0;
  padding: 0;
}

html {
  width: 100%
}

body {
  background-attachment: fixed;
  background-image: url('/images/background.jpg');
  background-position: top left;
  background-repeat: repeat;
  color: #444444;
  font-family: @project-font;
  width: 100%;
}

a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.hide {
  display: none;
}
