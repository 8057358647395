section.not-found {
  float: none;
  margin: auto;
  max-width: @project-max-width;
  overflow: hidden;
  padding: 2em;

  h2 {
    padding: 1em;
    text-align: center;
    width: 100%;
  }

  p {
    text-align: center;
    width: 100%;
  }
}
