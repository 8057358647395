div.tags {
  color: #888888;
  width: 100%;

  @media @phones {
    font-size: 0.8em;
  }

  @media @tablets-portrait, @tablets-landscape, @desktop {
    font-size: 0.9em;
  }

  p {
    border: solid 1px #888888;
    border-radius: 0.2em;
    margin: 0.5em 0.5em 0 0;
    padding: 0.2em;

    img {
      margin-right: 0.2em;
      width: 18px;
    }
  }
}
